export default [
  {
    key: "profileId",
    initKey: "profile",
    label: "field.image",
    type: "async-single-image",
    path: "user",
    pw: 200,
    ph: 200,
    fullWidth: false,
    showUploadHistory: false,
    cols: 12,
  },
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "asyn-single-selection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "name",
  },
  {
    key: "departmentId",
    label: "field.department",
    rules: "required",
    type: "asyn-single-selection",
    repository: "department",
    selectionKey: "id",
    selectionLabel: "name",
    query: { limit: 0 },
  },
  {
    key: "positionId",
    label: "field.position",
    rules: "",
    type: "asyn-single-selection",
    repository: "position",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "managerId",
    label: "field.supervisor",
    rules: "",
    type: "asyn-single-selection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "name",
    label: "field.name",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "idCard",
    label: "field.idCard",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "email",
    label: "field.email",
    rules: "email|max:100",
    type: "text",
    cols: 6,
  },
  {
    label: "breadcrumb.accountInfomration",
    icon: "LockIcon",
    type: "divider",
  },
  {
    key: "username",
    label: "field.username",
    rules: "required|max:100",
    type: "text",
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: "password",
    name: "new-password",
    label: "field.password",
    rules: "required|min:6|max:50",
    type: "password",
    hideInView: true,
  },
  {
    key: "retypePassword",
    label: "field.retypePassword",
    rules: "required|confirmed:password",
    type: "password",
    hideInView: true,
  },
  {
    label: "breadcrumb.accessAbility",
    icon: "ToolIcon",
    type: "divider",
  },
  {
    key: "canReview",
    label: "field.canReview",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "canReviewFinal",
    label: "field.canReviewFinal",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "canApprove",
    label: "field.canApprove",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    hide: true,
    skip: true,
    class: "pt-2",
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
