<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #departmentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :query="{
                      limit: 0,
                      company_id: data.companyId,
                    }"
                    :ref="item.field.key"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #managerId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                    <span
                      class="text-muted"
                      v-if="isOptional(item.field.rules)"
                    >
                      - {{ $t("field.optional") }}
                    </span>
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :query="{
                      limit: 0,
                      department_id: data.departmentId,
                      id: !data.id,
                      manager_id: !data.id,
                    }"
                    :ref="item.field.key"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :clearable="item.field.clearable"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

const EmployeeRepository = Repository.get("employee");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    NAsyncSingleSelect,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      data: {
        companyId: null,
        departmentId: null,
        managerId: null,
        positionId: null,
        profileId: null,
        name: null,
        roles: [],
        email: null,
        username: null,
        password: null,
        canReview: false,
        canReviewFinal: false,
        canApprove: false,
        isEnable: true,
      },
      initData: {},
      loading: false,
    };
  },
  watch: {
    "data.companyId"(newValue, oldValue) {
      if (oldValue && newValue != oldValue) {
        this.$refs.departmentId.clear();
      }
    },
    "data.departmentId"(newValue, oldValue) {
      if (oldValue && newValue != oldValue) {
        this.$refs.managerId.clear();
      }
    },
  },
  mounted() {
    this.show();
  },
  methods: {
    isOptional(rules) {
      if (!rules) {
        return true;
      }

      return !rules.includes("required");
    },
    show() {
      EmployeeRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.initData = {
            ...data,
          };
        }
      });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      EmployeeRepository.update(this.$route.params.id, this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === "password" || value.key === "retypePassword") {
        value.rules = value.rules.replace("required|", "");
        value.rules = value.rules.replace("required", "");
      }

      return value;
    });

    const resource = "employee";
    const route = "employee";

    return { fields, resource, route };
  },
};
</script>
